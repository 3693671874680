import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Seo from "components/Seo"

import { NewsletterConfirmation } from "page_components/contact"

const NewsletterConfirmationPage = ({ data, location }) => (
  <Layout
    location={location}
    mainBackground={
      data?.allWpPage?.nodes[0]?.featuredImage?.node?.localFile?.publicURL
    }
    isHeaderTransparent
  >
    <NewsletterConfirmation />
  </Layout>
)

export const Head = () => (
  <Seo title="Thank you for signing up to the newsletter" noIndex />
)

export const query = graphql`
  query {
    allWpPage(filter: { id: { eq: "cG9zdDoxMzIxMQ==" } }) {
      nodes {
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default NewsletterConfirmationPage
